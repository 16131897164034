
// Converted Variables


// Custom Media Query Variables


/*

  TEXT ALIGN
  Docs: http://tachyons.io/docs/typography/text-align/

  Base
    t = text-align

  Modifiers
    l = left
    r = right
    c = center
    j = justify

  Media Query Extensions:
    -ns = not-small
    -m  = medium
    -l  = large
    -xl = xlarge

*/

@media #{$breakpoint-xlarge} {
  .tl-xl  { text-align: left; }
  .tr-xl  { text-align: right; }
  .tc-xl  { text-align: center; }
  .tj-xl  { text-align: justify; }
}
