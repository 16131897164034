/* This file is a Sass partial file storing variables for Franklin */

/* COLOURS ============================================= */

/* KCMC Colour Palette */
$kcmc-blue: #36a9e1;
$kcmc-dark-blue: #00389a;
$kcmc-black: #423e3c;
$kcmc-grey: #423e3c;
$kcmc-slate-grey: #d4d4d4;
$kcmc-pink: #da5377;
$kcmc-light-blue: #62c0ef;
$kcmc-purple: #6c1da7;
$kcmc-orange: #F1A915;
$kcmc-green: #1FA55F;
$kcmc-red: #BC3C42;

/* KCMC Colour Palette */
$cpi-blue: #005190;
$cpi-grey: #3C3C3B;

/* Hyperlink Colour Palette */
$link: $kcmc-blue;
$link-hover: $kcmc-pink;
$link-visited: $kcmc-purple;

/* Social Media Colour Palette */
$twitter: #1DA1F2;
$linkedin: #0077B5;
$youtube: #FF0000;
$facebook: #4267B2;
$email: $kcmc-green;
$whatsapp: #00C23A;


/* FONTS ============================================= */

/* Foundry Sterling is inlined by asynchronous template file  */
/* font files stored at ./src/fonts */
/* configure fonts at ./templates/_inlinecss/_site_fonts.css */

/* Font Variables */

$font-light: 300;
$font-regular: 400;
$font-demi: 500;
$font-bold: 700;
$font-xbold: 800;


/* TACHYONS VARIABLES ============================================= */

/* Font Families */
$sans-serif: proxima-nova, helvetica, 'helvetica neue', arial, sans-serif;
$serif: georgia, serif;
$code: consolas, monaco, monospace;

/* Text Sizes */
/* Note: Sizes may seem higher as they account for Foundry Sterling being smaller */
$font-size-headline: 4rem;      // 64px
$font-size-subheadline: 3rem;   // 48px
$font-size-1: 3rem;     // 48px
$font-size-2: 2.5rem;   // 40px
$font-size-3: 2rem;     // 32px
$font-size-4: 1.5rem;   // 24px
$font-size-5: 1.25rem;  // 20px
$font-size-6: 1.125rem; // 18px
$font-size-7: 0.9rem;   // 14px

/* Default Tachyons Text Sizes – for reference only
$font-size-headline: 4rem;
$font-size-subheadline: 3rem;
$font-size-1: 3rem;
$font-size-2: 2.25rem;
$font-size-3: 1.5rem;
$font-size-4: 1.25rem;
$font-size-5: 1rem;
$font-size-6: .875rem;
$font-size-7: .75rem; */

/* Kearning and Leading */
$letter-spacing-tight: -.05em;
$letter-spacing-1: .1em;
$letter-spacing-2: .25em;
$line-height-solid: 1;
$line-height-title: 1.2;
$line-height-copy: 1.5;

/* Text Widths */
$measure: 30em;
$measure-narrow: 20em;
$measure-wide: 34em;

/* Spacing */
$spacing-none: 0;
$spacing-extra-small: .25rem;
$spacing-small: .5rem;
$spacing-medium: 1rem;
$spacing-large: 2rem;
$spacing-extra-large: 4rem;
$spacing-extra-extra-large: 8rem;
$spacing-extra-extra-extra-large: 16rem;
$spacing-copy-separator: 1.5em;

/* Heights */
$height-1: 1rem;
$height-2: 2rem;
$height-3: 4rem;
$height-4: 8rem;
$height-5: 16rem;

/* Widths */
$width-1: 1rem;
$width-2: 2rem;
$width-3: 4rem;
$width-4: 8rem;
$width-5: 16rem;

/* Maximum Widths */
$max-width-1: 1rem;
$max-width-2: 2rem;
$max-width-3: 4rem;
$max-width-4: 8rem;
$max-width-5: 16rem;
$max-width-6: 32rem;
$max-width-7: 48rem;
$max-width-8: 64rem;
$max-width-9: 74rem;

/* Border Radius */
$border-radius-none: 0;
$border-radius-1: .125rem;
$border-radius-2: .25rem;
$border-radius-3: .5rem;
$border-radius-4: 1rem;
$border-radius-circle: 100%;
$border-radius-pill: 9999px;

/* Border Widths */
$border-width-none: 0;
$border-width-1: .125rem;
$border-width-2: .25rem;
$border-width-3: .5rem;
$border-width-4: 1rem;
$border-width-5: 2rem;

/* Box Shadows */
$box-shadow-1: 0 0 4px 2px rgba( 0, 0, 0, 0.1 );
$box-shadow-2: 0 0 4px 0 rgba(0,0,0,.1);
$box-shadow-3: 2px 2px 4px 2px rgba( 0, 0, 0, 0.1 );
$box-shadow-4: 2px 2px 8px 0 rgba( 0, 0, 0, 0.1 );
$box-shadow-5: 4px 4px 8px 0 rgba( 0, 0, 0, 0.1 );

/* Colours: Neutrals */
$black: $kcmc-black;
$grey: $kcmc-grey;
$silver: #999;
$light-silver: #aaa;
$moon-grey: #ccc;
$light-grey: #eee;
$near-white: #f4f4f4;
$white: #fff;
$transparent: transparent;

/* Colours: Blacks */
$black-90: rgba(0,0,0,.9);
$black-80: rgba(0,0,0,.8);
$black-70: rgba(0,0,0,.7);
$black-60: rgba(0,0,0,.6);
$black-50: rgba(0,0,0,.5);
$black-40: rgba(0,0,0,.4);
$black-30: rgba(0,0,0,.3);
$black-20: rgba(0,0,0,.2);
$black-10: rgba(0,0,0,.1);
$black-05: rgba(0,0,0,.05);
$black-025: rgba(0,0,0,.025);
$black-0125: rgba(0,0,0,.0125);

/* Colours: Whites */
$white-90: rgba(255,255,255,.9);
$white-80: rgba(255,255,255,.8);
$white-70: rgba(255,255,255,.7);
$white-60: rgba(255,255,255,.6);
$white-50: rgba(255,255,255,.5);
$white-40: rgba(255,255,255,.4);
$white-30: rgba(255,255,255,.3);
$white-20: rgba(255,255,255,.2);
$white-10: rgba(255,255,255,.1);
$white-05: rgba(255,255,255,.05);
$white-025: rgba(255,255,255,.025);
$white-0125: rgba(255,255,255,.0125);

/* Colours: Greys */
$grey-90: rgba(60,60,59,.9);
$grey-80: rgba(60,60,59,.8);
$grey-70: rgba(60,60,59,.7);
$grey-60: rgba(60,60,59,.6);
$grey-50: rgba(60,60,59,.5);
$grey-40: rgba(60,60,59,.4);
$grey-30: rgba(60,60,59,.3);
$grey-20: rgba(60,60,59,.2);
$grey-10: rgba(60,60,59,.1);
$grey-05: rgba(60,60,59,.05);
$grey-025: rgba(60,60,59,.025);
$grey-0125: rgba(60,60,59,.0125);

$grey-gradient: lighten($kcmc-grey, 73%);
$blue-overlay: rgba(0, 81, 144, .9);

/* Colours: Primary Palette */
$blue-dark: darken($kcmc-blue, 10%);
$blue: $kcmc-blue;
$blue-light: lighten($kcmc-blue, 10%);
$blue-lightest: lighten($kcmc-blue, 20%);
$dark-blue-dark: darken($kcmc-dark-blue, 10%);
$dark-blue: $kcmc-dark-blue;
$dark-blue-light: lighten($kcmc-dark-blue, 20%);


/* Colours: Secondary Palette */
$pink-dark: darken($kcmc-pink, 10%);
$pink: $kcmc-pink;
$pink-light: lighten($kcmc-pink, 10%);
$purple-dark: darken($kcmc-purple, 10%);
$purple: $kcmc-purple;
$purple-light: lighten($kcmc-purple, 10%);
$light-blue-dark: darken($kcmc-light-blue, 10%);
$light-blue: $kcmc-light-blue;
$light-blue-light: lighten($kcmc-light-blue, 10%);


/* Colours: Response Palette */
$red-dark: darken($kcmc-red, 10%);
$red: $kcmc-red;
$red-light: lighten($kcmc-red, 10%);
$orange-dark: darken($kcmc-orange, 10%);
$orange: $kcmc-orange;
$orange-light: lighten($kcmc-orange, 10%);
$green-dark: darken($kcmc-green, 10%);
$green: $kcmc-green;
$green-light: lighten($kcmc-green, 10%);

/* Breakpoint Variables */
$breakpoint-not-small-value: '30em';
$breakpoint-large-value: '50em';
$breakpoint-xlarge-value: '70em';

/* Custom Media Query Variables */
$breakpoint-not-small: 'screen and (min-width: #{$breakpoint-not-small-value})';
$breakpoint-medium: 'screen and (min-width: #{$breakpoint-not-small-value}) and (max-width: #{$breakpoint-large-value})';
$breakpoint-large: 'screen and (min-width: #{$breakpoint-large-value}) and (max-width: #{$breakpoint-xlarge-value})';
$breakpoint-xlarge: 'screen and (min-width: #{$breakpoint-xlarge-value})';