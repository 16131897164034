
// Converted Variables


// Custom Media Query Variables


/*

   SKINS:PSEUDO

   Customize the color of an element when
   it is focused or hovered over.

 */

 
/* Text Colour Hover */

.hover-black:hover,
.hover-black:focus { color: $black; }
.hover-grey:hover,
.hover-grey:focus { color: $grey; }
.hover-silver:hover,
.hover-silver:focus { color: $silver; }
.hover-light-silver:hover,
.hover-light-silver:focus { color: $light-silver; }
.hover-moon-grey:hover,
.hover-moon-grey:focus { color: $moon-grey; }
.hover-light-grey:hover,
.hover-light-grey:focus { color: $light-grey; }
.hover-near-white:hover,
.hover-near-white:focus { color: $near-white; }
.hover-white:hover,
.hover-white:focus { color: $white; }

.hover-black-90:hover,
.hover-black-90:focus { color: $black-90; }
.hover-black-80:hover,
.hover-black-80:focus { color: $black-80; }
.hover-black-70:hover,
.hover-black-70:focus { color: $black-70; }
.hover-black-60:hover,
.hover-black-60:focus { color: $black-60; }
.hover-black-50:hover,
.hover-black-50:focus { color: $black-50; }
.hover-black-40:hover,
.hover-black-40:focus { color: $black-40; }
.hover-black-30:hover,
.hover-black-30:focus { color: $black-30; }
.hover-black-20:hover,
.hover-black-20:focus { color: $black-20; }
.hover-black-10:hover,
.hover-black-10:focus { color: $black-10; }

.hover-white-90:hover,
.hover-white-90:focus { color: $white-90; }
.hover-white-80:hover,
.hover-white-80:focus { color: $white-80; }
.hover-white-70:hover,
.hover-white-70:focus { color: $white-70; }
.hover-white-60:hover,
.hover-white-60:focus { color: $white-60; }
.hover-white-50:hover,
.hover-white-50:focus { color: $white-50; }
.hover-white-40:hover,
.hover-white-40:focus { color: $white-40; }
.hover-white-30:hover,
.hover-white-30:focus { color: $white-30; }
.hover-white-20:hover,
.hover-white-20:focus { color: $white-20; }
.hover-white-10:hover,
.hover-white-10:focus { color: $white-10; }

.hover-grey-90:hover,
.hover-grey-90:focus { color: $grey-90; }
.hover-grey-80:hover,
.hover-grey-80:focus { color: $grey-80; }
.hover-grey-70:hover,
.hover-grey-70:focus { color: $grey-70; }
.hover-grey-60:hover,
.hover-grey-60:focus { color: $grey-60; }
.hover-grey-50:hover,
.hover-grey-50:focus { color: $grey-50; }
.hover-grey-40:hover,
.hover-grey-40:focus { color: $grey-40; }
.hover-grey-30:hover,
.hover-grey-30:focus { color: $grey-30; }
.hover-grey-20:hover,
.hover-grey-20:focus { color: $grey-20; }
.hover-grey-10:hover,
.hover-grey-10:focus { color: $grey-10; }

.hover-blue-dk:hover,
.hover-blue-dk:focus { color: $blue-dark; }
.hover-blue:hover,
.hover-blue:focus { color: $blue; }
.hover-blue-lt:hover,
.hover-blue-lt:focus { color: $blue-light; }
.hover-blue-ltr:hover,
.hover-blue-ltr:focus { color: $blue-lightest; }
.hover-dark-blue-dk:hover,
.hover-dark-blue-dk:focus { color: $dark-blue-dark; }
.hover-dark-blue:hover,
.hover-dark-blue:focus { color: $dark-blue; }
.hover-dark-blue-lt:hover,
.hover-dark-blue-lt:focus { color: $dark-blue-light; }

.hover-pink-dk:hover,
.hover-pink-dk:focus { color: $pink-dark; }
.hover-pink:hover,
.hover-pink:focus { color: $pink; }
.hover-pink-lt:hover,
.hover-pink-lt:focus { color: $pink-light; }
.hover-purple-dk:hover,
.hover-purple-dk:focus { color: $purple-dark; }
.hover-purple:hover,
.hover-purple:focus { color: $purple; }
.hover-purple-lt:hover,
.hover-purple-lt:focus { color: $purple-light; }
.hover-light-blue-dk:hover,
.hover-light-blue-dk:focus { color: $light-blue-dark; }
.hover-light-blue:hover,
.hover-light-blue:focus { color: $light-blue; }
.hover-light-blue-lt:hover,
.hover-light-blue-lt:focus { color: $light-blue-light; }

.hover-red-dk:hover,
.hover-red-dk:focus { color: $red-dark; }
.hover-red:hover,
.hover-red:focus { color: $red; }
.hover-red-lt:hover,
.hover-red-lt:focus { color: $red-light; }
.hover-orange-dk:hover,
.hover-orange-dk:focus { color: $orange-dark; }
.hover-orange:hover,
.hover-orange:focus { color: $orange; }
.hover-orange-lt:hover,
.hover-orange-lt:focus { color: $orange-light; }
.hover-green-dk:hover,
.hover-green-dk:focus { color: $green-dark; }
.hover-green:hover,
.hover-green:focus { color: $green; }
.hover-green-lt:hover,
.hover-green-lt:focus { color: $green-light; }

.hover-inherit:hover,
.hover-inherit:focus { color: inherit; }


/* Background Colour Hover */

.hover-bg-black:hover,
.hover-bg-black:focus { background-color: $black; }
.hover-bg-grey:hover,
.hover-bg-grey:focus { background-color: $grey; }
.hover-bg-silver:hover,
.hover-bg-silver:focus { background-color: $silver; }
.hover-bg-light-silver:hover,
.hover-bg-light-silver:focus { background-color: $light-silver; }
.hover-bg-moon-grey:hover,
.hover-bg-moon-grey:focus { background-color: $moon-grey; }
.hover-bg-light-grey:hover,
.hover-bg-light-grey:focus { background-color: $light-grey; }
.hover-bg-near-white:hover,
.hover-bg-near-white:focus { background-color: $near-white; }
.hover-bg-white:hover,
.hover-bg-white:focus { background-color: $white; }
.hover-bg-transparent:hover,
.hover-bg-transparent:focus { background-color: $transparent; }

.hover-bg-black-90:hover,
.hover-bg-black-90:focus { background-color: $black-90; }
.hover-bg-black-80:hover,
.hover-bg-black-80:focus { background-color: $black-80; }
.hover-bg-black-70:hover,
.hover-bg-black-70:focus { background-color: $black-70; }
.hover-bg-black-60:hover,
.hover-bg-black-60:focus { background-color: $black-60; }
.hover-bg-black-50:hover,
.hover-bg-black-50:focus { background-color: $black-50; }
.hover-bg-black-40:hover,
.hover-bg-black-40:focus { background-color: $black-40; }
.hover-bg-black-30:hover,
.hover-bg-black-30:focus { background-color: $black-30; }
.hover-bg-black-20:hover,
.hover-bg-black-20:focus { background-color: $black-20; }
.hover-bg-black-10:hover,
.hover-bg-black-10:focus { background-color: $black-10; }

.hover-bg-white-90:hover,
.hover-bg-white-90:focus { background-color: $white-90; }
.hover-bg-white-80:hover,
.hover-bg-white-80:focus { background-color: $white-80; }
.hover-bg-white-70:hover,
.hover-bg-white-70:focus { background-color: $white-70; }
.hover-bg-white-60:hover,
.hover-bg-white-60:focus { background-color: $white-60; }
.hover-bg-white-50:hover,
.hover-bg-white-50:focus { background-color: $white-50; }
.hover-bg-white-40:hover,
.hover-bg-white-40:focus { background-color: $white-40; }
.hover-bg-white-30:hover,
.hover-bg-white-30:focus { background-color: $white-30; }
.hover-bg-white-20:hover,
.hover-bg-white-20:focus { background-color: $white-20; }
.hover-bg-white-10:hover,
.hover-bg-white-10:focus { background-color: $white-10; }

.hover-bg-grey-90:hover,
.hover-bg-grey-90:focus { background-color: $grey-90; }
.hover-bg-grey-80:hover,
.hover-bg-grey-80:focus { background-color: $grey-80; }
.hover-bg-grey-70:hover,
.hover-bg-grey-70:focus { background-color: $grey-70; }
.hover-bg-grey-60:hover,
.hover-bg-grey-60:focus { background-color: $grey-60; }
.hover-bg-grey-50:hover,
.hover-bg-grey-50:focus { background-color: $grey-50; }
.hover-bg-grey-40:hover,
.hover-bg-grey-40:focus { background-color: $grey-40; }
.hover-bg-grey-30:hover,
.hover-bg-grey-30:focus { background-color: $grey-30; }
.hover-bg-grey-20:hover,
.hover-bg-grey-20:focus { background-color: $grey-20; }
.hover-bg-grey-10:hover,
.hover-bg-grey-10:focus { background-color: $grey-10; }

.hover-bg-blue-dk:hover,
.hover-bg-blue-dk:focus { background-color: $blue-dark; }
.hover-bg-blue:hover,
.hover-bg-blue:focus { background-color: $blue; }
.hover-bg-blue-lt:hover,
.hover-bg-blue-lt:focus { background-color: $blue-light; }
.hover-bg-dark-blue-dk:hover,
.hover-bg-dark-blue-dk:focus { background-color: $dark-blue-dark; }
.hover-bg-dark-blue:hover,
.hover-bg-dark-blue:focus { background-color: $dark-blue; }
.hover-bg-dark-blue-lt:hover,
.hover-bg-dark-blue-lt:focus { background-color: $dark-blue-light; }

.hover-bg-pink-dk:hover,
.hover-bg-pink-dk:focus { background-color: $pink-dark; }
.hover-bg-pink:hover,
.hover-bg-pink:focus { background-color: $pink; }
.hover-bg-pink-lt:hover,
.hover-bg-pink-lt:focus { background-color: $pink-light; }
.hover-bg-purple-dk:hover,
.hover-bg-purple-dk:focus { background-color: $purple-dark; }
.hover-bg-purple:hover,
.hover-bg-purple:focus { background-color: $purple; }
.hover-bg-purple-lt:hover,
.hover-bg-purple-lt:focus { background-color: $purple-light; }
.hover-bg-light-blue-dk:hover,
.hover-bg-light-blue-dk:focus { background-color: $light-blue-dark; }
.hover-bg-light-blue:hover,
.hover-bg-light-blue:focus { background-color: $light-blue; }
.hover-bg-light-blue-lt:hover,
.hover-bg-light-blue-lt:focus { background-color: $light-blue-light; }

.hover-bg-red-dk:hover,
.hover-bg-red-dk:focus { background-color: $red-dark; }
.hover-bg-red:hover,
.hover-bg-red:focus { background-color: $red; }
.hover-bg-red-lt:hover,
.hover-bg-red-lt:focus { background-color: $red-light; }
.hover-bg-orange-dk:hover,
.hover-bg-orange-dk:focus { background-color: $orange-dark; }
.hover-bg-orange:hover,
.hover-bg-orange:focus { background-color: $orange; }
.hover-bg-orange-lt:hover,
.hover-bg-orange-lt:focus { background-color: $orange-light; }
.hover-bg-green-dk:hover,
.hover-bg-green-dk:focus { background-color: $green-dark; }
.hover-bg-green:hover,
.hover-bg-green:focus { background-color: $green; }
.hover-bg-green-lt:hover,
.hover-bg-green-lt:focus { background-color: $green-light; }

.hover-bg-inherit:hover,
.hover-bg-inherit:focus { background-color: inherit; }
