
// Converted Variables


// Custom Media Query Variables


/*

   FONT STYLE
   Docs: http://tachyons.io/docs/typography/font-style/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.i         { font-family: $sans-serif; font-style: italic; }
.fs-normal { font-style: normal; }

@media #{$breakpoint-not-small} {
  .i-ns       { font-family: $sans-serif; font-style: italic; }
  .fs-normal-ns     { font-style: normal; }
}

@media #{$breakpoint-medium} {
  .i-m       { font-family: $sans-serif; font-style: italic; }
  .fs-normal-m     { font-style: normal; }
}

@media #{$breakpoint-large} {
  .i-l       { font-family: $sans-serif; font-style: italic; }
  .fs-normal-l     { font-style: normal; }
}

@media #{$breakpoint-xlarge} {
  .i-xl       { font-family: $sans-serif; font-style: italic; }
  .fs-normal-xl     { font-style: normal; }
}
