
// Converted Variables


// Custom Media Query Variables


/*

   POSITIONING
   Docs: http://tachyons.io/docs/layout/position/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large
     -xl = xlarge

*/


@media #{$breakpoint-xlarge} {
  .static-xl { position: static; }
  .relative-xl  { position: relative; }
  .absolute-xl  { position: absolute; }
  .fixed-xl  { position: fixed; }
}
