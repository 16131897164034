/* VARIABLES AND MIXINS ============================================= */

@import "variables"; // includes both tachyons and custom variables
@import "mixins"; // includes custom mixins to streamline development


/* TACHYONS ============================================= */

/*

IMPORTANT - READ FIRST BEFORE EDITING:
If a module needs customising more than the variables above allow,
copy the .scss file from the node_modules folder and place into the
'src/scss/_tachyons-extended' folder and refer to the module there.
Be sure to comment out the old module in '_tachyons.scss', and add
the new file to "_tachyons-extended.scss". You can then start
rewriting the module there, so your changes won't be lost when
Tachyons gets updated in the 'node_modules' folder.

*/

@import "tachyons"; // switchboard for tachyon modules
@import "tachyons-extended"; // modules extensions, replacements and base styles


/* LAYOUT ============================================= */

@import "src/scss/layout/header";
@import "src/scss/layout/footer";
//@import "src/scss/layout/section";
//@import "src/scss/layout/masonry";


/* FORM ============================================= */

//@import "src/scss/form/general";
//@import "src/scss/form/input";
//@import "src/scss/form/textarea";
//@import "src/scss/form/select";
//@import "src/scss/form/checkbox";
//@import "src/scss/form/radio";
//@import "src/scss/form/file";
//@import "src/scss/form/slider";


/* ELEMENTS ============================================= */

//@import "src/scss/elements/avatar";
//@import "src/scss/elements/button";
//@import "src/scss/elements/delete";
//@import "src/scss/elements/icon";
//@import "src/scss/elements/image";
//@import "src/scss/elements/loader";
//@import "src/scss/elements/notification";
//@import "src/scss/elements/progress-bar";
//@import "src/scss/elements/table";
//@import "src/scss/elements/tag";


/* COMPONENTS ============================================= */

//@import "src/scss/components/breadcrumb";
//@import "src/scss/components/card";
//@import "src/scss/components/dropdown";
//@import "src/scss/components/menu";
//@import "src/scss/components/message";
//@import "src/scss/components/modal";
@import "src/scss/components/navbar";
//@import "src/scss/components/pagination";
//@import "src/scss/components/tabs";