
// Converted Variables


// Custom Media Query Variables


/*

  CUSTOM STYLES

*/

/* GLOBAL ============================================= */

/* Cookies */
div#hs-eu-cookie-confirmation {
  position: sticky !important;
}

@media #{$breakpoint-not-small} {
    div#hs-eu-cookie-confirmation {
      position: fixed !important;
      bottom: 0;
      top: inherit !important;
    }
}

/* Typography */
body {
  font-family: $sans-serif;
  font-weight: $font-regular;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased; // fix safari bug with weighted text
  line-height: 1.4; // 140% leading
  letter-spacing: .1px;
  color: $grey;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $sans-serif;
font-weight: $font-bold;
  line-height: 1.2; // 120% leading
}
p {
  margin-top: 0;
}
b, strong {
  font-family: $sans-serif;
}
i {
  font-family: $sans-serif;
  font-style: italic;
}

/* Redactor - text formatting */
.tf {
  a:not([class]) {
    font-family: $sans-serif;
font-weight: $font-demi;
    color: $blue;
    text-decoration: none;
    border-bottom: .115em solid $link-hover;
    transition: color .15s ease-in;
    &:hover {
      color: $link-hover;
    }
    &:visited {
      border-bottom: .115em solid $link-visited;
      &:hover {
        color: $link-visited;
      }
    }
  }
  h2, h3, h4 {
    margin-top: $spacing-none;
    margin-bottom: $spacing-medium;
  }
  h2, h3 {
    font-size: $font-size-4;
  }
  h4 {
    font-size: $font-size-6;
  }
  &.hero {
    h1 {
      margin-top: $spacing-none;
      font-size: $font-size-3;
      line-height: 1.25;
      font-weight: 700;
      max-width: 34em;
      @media #{$breakpoint-medium} {
        font-size: $font-size-2;
      }
      @media #{$breakpoint-large} {
        font-size: $font-size-1;
      }
      @media #{$breakpoint-xlarge} {
        font-size: $font-size-headline;
      }
    }
    h2 {
      font-size: $font-size-4;
      font-weight: 700;
      max-width: 30em;
      line-height: 1.4;
      font-family: $sans-serif;
font-weight: $font-demi;
      @media #{$breakpoint-not-small} {
        font-size: $font-size-3;
      }
      
    }
    h3 {
      font-size: $font-size-5;
      font-weight: 700;
      max-width: 30em;
      font-family: $sans-serif;
font-weight: $font-demi;
      @media #{$breakpoint-not-small} {
        font-size: $font-size-4;
      }
    }
    &.black {
      a {
        color: $blue;
        border-bottom: 0.1em solid $pink;
        transition: color .15s ease-in;
        &:visited {
          color: $blue;
          border-bottom: 0.1em solid $pink;
        }
        &:hover {
          color: $pink;
        }
      }
    }
    &.white {
      a {
        color: $white;
        border-bottom: 0.1em solid $pink;
        transition: color .15s ease-in;
        &:visited {
          color: $white;
          border-bottom: 0.115em solid $pink;
        }
        &:hover {
          color: $pink;
        }
      }
    ul {
      padding-left: 0;
      margin-left: 20px;
    }
    }


  }
  blockquote {
    margin-left: $spacing-none;
    margin-top: 0;
    margin-bottom: $spacing-medium;
    padding-left: $spacing-medium*3;
    line-height: $line-height-copy;
    font-size: $font-size-6;
    max-width: $measure;
    position: relative;
      &::before {
        content:"“";
        position: absolute;
        top: 0;
        left: 0;
        font-size: 3.5em;
        margin: 0;
        padding: 0;
        padding-top: 28px;
        line-height: 20px;
      }
      & + p {
        padding-left: $spacing-medium*3;
      }
  }

  @media #{$breakpoint-not-small} {
    h2 {
      font-size: $font-size-3;
    }
  }
  @media #{$breakpoint-large} {
    h4 {
      font-size: $font-size-5;
    }
    blockquote {
      font-size: $font-size-4;
    }
  }
  @media #{$breakpoint-xlarge} {
    h4 {
      font-size: $font-size-4;
    }
    blockquote {
      font-size: $font-size-4;
    }
  }
}

.avatar {
  width: 74px;
  height: 74px;
}

/* Outlines (Tabhandler JS Styles) */
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) a:focus {
  outline: none;
}

/* Accessibility */
.sr-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

/* Object Fit Fix for Images */
.object-fit-cover {
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

/* SVG FIX for IE */
svg { width: 100%; height: 100%;}

/* Block BACKGROUND */
.kcmc-bg {
  background: url(https://staging.materialschemistry.org.uk/img/kcmc-bg.png) no-repeat center center;
  background-size: cover;
}

/* Block HERO */
.round-out { 
  clip-path: ellipse(150% 100% at 50% 0); 
  -webkit-clip-path: ellipse(150% 100% at 50% 0);
}

/* Gradients - used in Hero Sections */
.gl-dark-blue {
  @include scrimGradient($kcmc-blue, 1, 'to left');
}
.gr-dark-blue {
  @include scrimGradient($kcmc-blue, 1, 'to right');
}
.gb-grey{
  @include scrimGradient($grey-gradient, 1, 'to bottom');
}


.false-floor {
  bottom: -25vh;
  height: 25vh;
}

/* Tables */
table {
  width: 100%;
  border-spacing: 0;
  border-collapse: 0;
}
th {
  font-family: $sans-serif;
font-weight: $font-demi;
  padding: 0 1rem 1rem 0;
  text-align: left;
  border-bottom: 2px solid $grey-10;
}
td {
  padding: .6rem .8rem .8rem 0;
  border-bottom: 1px solid $grey-10;
}



/* Divider */
.divider {
    background: $grey-10;
    content: "";
    display: block;
    height: 2px;
    position: relative;
    width: 100%;
}
/* Divider - to be used with content */
.divider-c, .divider-r, .divider-l {
    overflow: hidden;
    text-align: center;
}
.divider-c:before,
.divider-c:after,
.divider-l:before,
.divider-r:after {
    background: $grey-10;
    content: "";
    display: inline-block;
    height: 3px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}
.divider-c:before, .divider-l:before {
    right: 1rem;
    margin-left: -50%;
}
.divider-c:after, .divider-r:after {
    left: 1rem;
    margin-right: -50%;
}



/* Divider - for stepped forms */

.list {
  .divider-c:before,
  .divider-l:before {
      right: 0;
      margin-left: -50%;
  }
  .divider-c:after,
  .divider-r:after {
      left: 0;
      margin-right: -50%;
  }
  .divider-c,
  .divider-l,
  .divider-r {
    width: 60px;
    display: inline-flex;
    @media #{$breakpoint-not-small} {
        display: block;
        width: 100%;
    }
  }
  .divider-c:before,
  .divider-c:after,
  .divider-l:before,
  .divider-r:after {
    content: none;
    background: $white;
     @media #{$breakpoint-not-small} {
       background: $grey-10;
       content: "";
     }
  }
}



/* Bread Crumbs */
a.crumb {
  i, span {
    color: $blue;
  }
  &:hover i, &:hover span {
    color: $pink;
  }
}

/* Funding Body */
.fund {
  max-width: 190px;
}


/* Rotation Classes  */
.r-90 {
  transform: rotate(90deg);
}
.rm-90 {
  transform: rotate(-90deg);
}


/* Related and Case Study Cards  */
.card-related {
  & div > a > picture > img {
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;
  }
  &:hover div > div > div > div a.link {
    transition: background-color .15s ease-in-out;
    background-color: $white;
    color: $dark-blue;
  }
  &:hover div > a > picture > img {
    transform: scale(1.05);
  }
  &:focus div > a > picture > img {
    transform: scale(1.05);
  }
  .h-card {
    display: flex;
    flex-direction: column;
    height: 40vh;
    @media #{$breakpoint-not-small} {
      height: auto;
    }
    @media #{$breakpoint-medium} {
      height: 40vh;
    }
  }


  @media #{$breakpoint-large} {
    .h-card {
      min-height: 100%;
    }
  }
  @media #{$breakpoint-xlarge} {
    .h-card {
      min-height: 100%;
    }
  }
}


/* Lists  */
.card {
  & div > a > picture > img {
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;
  }
  &:hover div > a > picture > img {
    transform: scale(1.05);
  }
  &:focus div > a > picture > img {
    transform: scale(1.05);
  }
  & div > a > svg {
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;
  }
  &:hover div > a > svg {
    transform: scale(1.05);
  }
  &:focus div > a > svg {
    transform: scale(1.05);
  }

}


.c-list, .card {
  div a p {
    color: $grey;
  }
  &:hover div a h4 {
    transition: color .15s ease-in-out;
    color: $pink;
  }
}


/* Dot Image Treatment  */
.dotsOne, .dotsTwo, .dotsThree {
  width: 80px;
  height: 80px;
  &.dotsTopLeft {
      transform: rotate(-90deg);
  }
  &.dotsBottomRight {
      transform: rotate(90deg);
  }
  &.dotsBottomLeft {
      transform: rotate(-180deg);
  }
}

.dotsOne {
  &.dotsTopRight {
      top: -46px;
      right: -11px;
  }
  &.dotsTopLeft {
      top: -11px;
      left: -46px;
  }
  &.dotsBottomRight {
      bottom: -11px;
      right: -46px;
  }
  &.dotsBottomLeft {
      bottom: -46px;
      left: -11px;
  }
}

.dotsTwo {
  &.dotsTopRight {
      top: -52px;
      right: -25px;
  }
  &.dotsTopLeft {
      top: -25px;
      left: -52px;
  }
  &.dotsBottomRight {
      bottom: -25px;
      right: -52px;
  }
  &.dotsBottomLeft {
      bottom: -52px;
      left: -25px;
  }
}

.dotsThree {
  &.dotsTopRight {
      top: -23px;
      right: -19px;
  }
  &.dotsTopLeft {
      top: -19px;
      left: -23px;
  }
  &.dotsBottomRight {
      bottom: -19px;
      right: -23px;
  }
  &.dotsBottomLeft {
      bottom: -23px;
      left: -19px;
  }
}



@media #{$breakpoint-large, $breakpoint-xlarge} {
  .dotsOne, .dotsTwo, .dotsThree {
    width: 100px;
    height: 100px;
  }

figure.fl {
  .dotsOne {
    &.dotsTopRight {
        right: 8px;
        top: -57px;
    }
    &.dotsBottomRight {
        right: -26px;
    }
    &.dotsTopLeft {
        left: -58px;
    }
    &.dotsBottomLeft {
        bottom: -58px;
    }    
  }
  .dotsTwo {
    &.dotsTopRight {
        right: 1px;
        top: -68px;
    }
    &.dotsBottomRight {
        right: -35px;
    }
    &.dotsTopLeft {
        left: -68px;
    }
    &.dotsBottomLeft {
        bottom: -68px;
    }
  }

  .dotsThree {
    &.dotsTopLeft {
        top: -24px;
        left: -28px;
    }
    &.dotsBottomLeft {
        bottom: -28px;
        left: -24px;
    }
  }
}

figure.fr {
  .dotsOne {
    &.dotsTopRight {
        right: -11px;
        top: -57px;
    }
    &.dotsBottomRight {
        right: -58px;
    }
    &.dotsTopLeft {
        left: -26px;
    }
    &.dotsBottomLeft {
        left: 22px;
        bottom: -58px;
    }    
  }
  .dotsTwo {
    &.dotsTopRight {
        right: -31px;
        top: -68px;
    }
    &.dotsBottomRight {
        right: -68px;
    }
    &.dotsTopLeft {
        left: -38px;
    }
    &.dotsBottomLeft {
        left: 7px;
        bottom: -68px;
    }
  }
  .dotsThree {
    &.dotsTopRight {
        top: -28px;
        right: -24px;
    }
    &.dotsBottomRight {
        bottom: -24px;
        right: -28px;
    }
    &.dotsTopLeft {
        top: -24px;
        left: 5px;
    }
    &.dotsBottomLeft {
        bottom: -28px;
        left: 8px;
    }
  }
}


}

/* Dot Colours  */
.dotsBlue {
  .colorDots {
    fill: $blue;
  }
}
.dotsDarkBlue {
  .colorDots {
    fill: $dark-blue;
  }
}
.dotspink {
  .colorDots {
    fill: $pink;
  }
}
.dotsPurple {
  .colorDots {
    fill: $purple;
  }
}

/* Overlay Shadow Layer - visible when navigation or search is open */
.overlay {
  position: fixed;
  z-index: 998;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  backface-visibility: hidden;
  background: #000;
  background: rgba(0,0,0,.85);
  overflow-y: auto;
  -webkit-overflow-scrolling:touch;
  display: block;
}
.show-overlay {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s 0s, visibility 0s 0s, transform .3s 0s;
}
.no-scroll {
  position:fixed;
  overflow:hidden;
  width: 100%;
}

/* Modal  */

.blocker {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  width: 100%; height: 100%;
  overflow: auto;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;
  background-color: $blue-overlay;
  text-align: center;
}
.blocker:before{
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}
.blocker.behind {
  background-color: transparent;
}
.modal {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  background: #fff;
  padding: 15px 30px;
  box-shadow: 0 1px 1px rgba(10,10,10,0.1);
  text-align: left;
}

.modal a.close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  i {
    padding-left: 0.1em;
  }
}

.modal-spinner {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #111;
  height: 20px;
}

.modal-spinner > div {
  border-radius: 100px;
  background-color: #fff;
  height: 20px;
  width: 2px;
  margin: 0 1px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.modal-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.modal-spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.modal-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.5) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.5);
    -webkit-transform: scaleY(0.5);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}







/* Forms */

/* Hide the honeypot field */
input#poohbear { display: none; }

.pagemenu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.checkbox {
  width: 28px;
  height: 28px;
}
.checkboxGroup {
  label {
      padding-left: 5px;
      margin-top: 0;
      padding-top: 0;
      line-height: 0;
      font-size: 16px;
  }
}
.form-errors {
  color: $white;
  padding: 20px;
  background: $red;
  margin-bottom: 30px;
  border-radius: 3px;
  font-family: $sans-serif;
font-weight: $font-bold;
}
.errors {
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  color: $red;
  font-family: $sans-serif;
font-weight: $font-bold;
  list-style: none;
}


button, input, optgroup, select, textarea {
  font-family: $sans-serif;
font-weight: $font-regular;
}
/* Form Selection */
input::selection {
  background: $pink;
}
select option:checked {
  background: $pink -webkit-linear-gradient(bottom, $pink 0%, $pink 100%);
}


/* Accodion Tabs */


section {
  zoom: 1;
  position: relative;
  height: auto;
  margin-top: 1em;
  a.tab-link {
    cursor: pointer;
    margin-left: 0.9em;
    margin-top: 0.8em;
    &:hover {
      span i  {
          color: $pink;
          transition: color .15s ease-in;
        }
    }
  }
  a.active  {
      color: $pink;
        span i  {
          color: $pink;
          transition: color .15s ease-in;
        }
		}
}
section:after,
section:before {
  content: "";
  display: table;
}
section:after {
  clear: both;
}


section div.tab-content {
  zoom: 1;
  position: relative;
  height: auto;
  display: none;
}

section div.tab-content:after,
section div.tab-content:before {
  content: "";
  display: table;
}

section div.tab-content:after {
  clear: both;
}

@media #{$breakpoint-not-small} {

  section a.tab-link {
    position: relative;
    width: 33.333333333333336%;
    height: auto;
    display: block;
  }

  section div.tab-content {
    position: absolute;
    width: 66.66666666666667%;
    right: 0;
    top: 0;
  }

  section a.active {
    span i {
        transform: rotate(-90deg);
    }
  }
}

section a.active + div.tab-content {
  display: block;
}
