
// Converted Variables


// Custom Media Query Variables


/*

   LINKS
   Docs: http://tachyons.io/docs/elements/links/

*/

.link-visited:visited {
  border-bottom: $kcmc-purple;
  &:hover, &:focus {
    color: $kcmc-purple;
  }
}

.disabled-link {
  pointer-events: none;
}