
// Converted Variables


// Custom Media Query Variables


/*

   BORDER WIDTHS
   Docs: http://tachyons.io/docs/themes/borders/

   Base:
     bw = border-width

   Modifiers:
     0 = 0 width border
     1 = 1st step in border-width scale
     2 = 2nd step in border-width scale
     3 = 3rd step in border-width scale
     4 = 4th step in border-width scale
     5 = 5th step in border-width scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.bw-link {
  border-width: 0.115em;
}