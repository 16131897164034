/* NAVBAR STYLES ============================================= */

/* Navigation */
main {
  padding-top: 61px;
}
.navbar {
  height: 61px;
  line-height: 2rem;
  box-shadow: 0 1px 1px rgba(10,10,10,0.1);
}
.nav-buttons {
  top: 9px;
  right: 0;
}

nav .logo {
  width: 7.3rem;
  height: 1.9rem;
}

.navbar .active-item:hover  i,
.navbar .active-item:focus  i,
.navbar .active-item:active i {
  transform: rotate(180deg);
  padding: 0 .25rem 0 0;
}

.menu-text {
  font-size: 1.2em;
}

.main-menu a {
  font-size: 0.8em;
}
.main-menu .menu-close {
  font-size: 1em;
}

@media #{$breakpoint-not-small} {
  .main-menu a {
    font-size: 1em;
  }
  main {
    padding-top: 116px;
  }
  .navbar {
    height: 116px;
  }

  .nav-buttons {
  top: 36px;
  right: 0;
  }

  nav .logo {
    width: 12rem;
    height: 3.1rem;
  }
}
@media #{$breakpoint-large} {

}
@media #{$breakpoint-xlarge} {
  .navbar {
    line-height: 0;
    overflow: visible;
  }
}


/* Search */
input[type="search"]::-ms-clear {
  /* removes close icon - IE */
  display: none;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.search {
  height: 61px;
  top: 61px;
  opacity: 0;
  visibility: hidden;
  border-bottom: 1px solid $grey-10;
}
.search input {
  appearance: none;
  background-color: transparent;
  border-bottom: 5px solid $white;

}
.search input::placeholder {
  color: $white-60;
  font-style: italic;
}
.search.is-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s 0s, visibility 0s 0s;
}
@media #{$breakpoint-not-small} {
  .search {
    height: 100px;
    top: 100px;
  }
}
@media #{$breakpoint-large} {
  .search {
    height: 90px;
  }
}
@media #{$breakpoint-xlarge} {
  .search {
    height: 120px;
  }
}
.search-trigger, .cd-nav-trigger {
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  overflow: hidden;
  white-space: nowrap;
  /* hide text */
  color: transparent;
  z-index: 3;
}
.search-trigger::before, .search-trigger::after {
  /* search icon */
  content: '';
  position: absolute;
  transition: opacity 0.3s;
}
.search-trigger::before {
  /* lens */
  top: 11px;
  left: 11px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid $kcmc-grey;
}
.search-trigger::after {
  /* handle */
  height: 3px;
  width: 8px;
  background: $kcmc-grey;
  bottom: 14px;
  right: 11px;
  transform: rotate(45deg);
}
.search-trigger span {
  /* container for the X icon */
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.search-trigger span::before, .search-trigger span::after {
  /* close icon */
  content: '';
  position: absolute;
  display: inline-block;
  height: 3px;
  width: 22px;
  top: 50%;
  margin-top: -2px;
  left: 50%;
  margin-left: -11px;
  background: $kcmc-grey;
  opacity: 0;
}
.search-trigger span::before {
  transform: rotate(45deg);
}
.search-trigger span::after {
  transform: rotate(-45deg);
}
.search-trigger.search-is-visible::before, .search-trigger.search-is-visible::after {
  /* hide search icon */
  opacity: 0;
}
.search-trigger.search-is-visible span::before, .search-trigger.search-is-visible span::after {
  /* show close icon */
  opacity: 1;
}
.search-trigger.search-is-visible span::before {
  transform: rotate(135deg);
}
.search-trigger.search-is-visible span::after {
  transform: rotate(45deg);
}
.no-js .search {
  position: relative;
  top: 0;
  opacity: 1;
  visibility: visible;
}
.main-menu span.search-button {
  margin-right:55px;
}
.main-menu span:hover.search-button, .main-menu span:focus.search-button {
  color: $dark-blue;
  background: none;
}
.search-icon {
  margin-right: 18px;
}





/* Screen reader only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

/* Button styling */
.menu-toggle {
  display: inline-block;
  padding: .75em 15px;
  line-height: 1em;
  font-size: 1em;
  color: $kcmc-grey;
  cursor: pointer;
}

.menu-toggle:hover,
#main-menu-checkbox:focus ~ .offscreen .menu-toggle {
  color: $kcmc-pink;
  outline: none;
}

#main-menu-checkbox {
  position: absolute;
  right: -100vw; /* get it off the screen */
}

/*
 Default styles + Mobile first
 Offscreen menu style
*/
.main-menu {
  position: absolute;
  right: -282px;
  top: 0;
  height: 100%;
	overflow-y: scroll;
	overflow-x: visible;
	transition: right 0.3s ease,
	box-shadow 0.3s ease;
	z-index: 999;
}

.main-menu ul {
  list-style: none;
  margin: 0;
  /* Hide shadow w/ -8px while 'closed' */
  -webkit-box-shadow: -8px 0 8px rgba(0,0,0,.5);
     -moz-box-shadow: -8px 0 8px rgba(0,0,0,.5);
          box-shadow: -8px 0 8px rgba(0,0,0,.5);
  min-height: 100%;
  width: 282px;
  background: $kcmc-blue;
  padding: 2.5em 0 0;
  padding-left: 35px;
}
.main-menu a { 
  border-left: 2px solid $white;
  padding-left: 10px;
}
.main-menu a.active {
  border-left: 2px solid $kcmc-dark-blue;
  background: $kcmc-dark-blue;
}

.main-menu a,
.main-menu .menu-close {
  display: block;
  padding: .75em 15px;
  line-height: 1em;
  color: $white;
  text-decoration: none;
  text-transform: uppercase;
}

.main-menu li:first-child a {
  margin-top: 60px;
}

.main-menu a:hover {
  border-left: 2px solid $kcmc-dark-blue;
  background: $kcmc-dark-blue;
}
.main-menu .menu-close {
  border-radius: 50px;
}
.main-menu .menu-text:hover {
  color: $kcmc-dark-blue;
}

#main-menu-checkbox:focus ~ .offscreen .menu-close {
}

.main-menu .menu-close {
  position: absolute;
  right: 69px;
  top: 36px;
  cursor: pointer;
}

/*
 On small devices, allow it to toggle...
*/
/*
 :target for non-JavaScript
 aria-expanded="true/false" will be for JavaScript
*/
#main-menu-checkbox:checked ~ .offscreen .main-menu,
.main-menu[aria-expanded="true"] {
  right: 0;
  outline: none;
  -moz-box-shadow: 3px 0 12px rgba(0,0,0,.25);
  -webkit-box-shadow: 3px 0 12px rgba(0,0,0,.25);
  box-shadow: 3px 0 12px rgba(0,0,0,.25);
}

#main-menu-checkbox:checked ~ .offscreen .menu-close,
.main-menu[aria-expanded="true"] .menu-close {
  z-index: 1001;
}

#main-menu-checkbox:checked ~ .offscreen .main-menu ul,
.main-menu[aria-expanded="true"] ul {
  position: relative;
  z-index: 1000;
}

/* 
 We could us `.main-menu:target:after`, but
 it wouldn't be clickable.
*/
#main-menu-checkbox:checked ~ .offscreen .backdrop,
.main-menu[aria-expanded="true"] + .backdrop {
  position: absolute;
  display: block;  
  content: "";
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: $kcmc-black;
  background: rgba(0,0,0,.85);
  cursor: default;
}

@supports (position: fixed) {
  .main-menu,
  #main-menu-checkbox:checked ~ .offscreen .backdrop,
  .main-menu[aria-expanded="true"] + .backdrop {
    position: fixed;
  }
}

