/* This file is a Sass partial file storing mixins for Franklin */

/* Gradient ============================================= */

@mixin scrimGradient($startColor: $white, $startOpacity: 1, $direction: 'to bottom') {

  $scrimCoordinates: (
    0: $startOpacity,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  background: linear-gradient(unquote($direction), $stops);
	background: -moz-linear-gradient(unquote($direction), $stops);
	background: -webkit-linear-gradient(unquote($direction), $stops);

}


/* TRUNCATE ============================================= */

/* 
A mixin that generates CSS for an effect added to a string containing element whose text will be truncated and then an extra character of ellipsis will be added.
{Number} $width [100%] is the value that defines where the text will be truncated. 

Usage:
.truncate{
  @include truncate(2em);
}
*/

@mixin truncate($width: 100%){
	width: $width;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}