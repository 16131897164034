
// Converted Variables


// Custom Media Query Variables


/*

   TYPE SCALE
   Docs: http://tachyons.io/docs/typography/scale/

   Base:
    f = font-size

   Modifiers
     1 = 1st step in size scale
     2 = 2nd step in size scale
     3 = 3rd step in size scale
     4 = 4th step in size scale
     5 = 5th step in size scale
     6 = 6th step in size scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large
     -xl  = xlarge
*/


/* Type Scale */


@media #{$breakpoint-xlarge} {
  .f-6-xl,
  .f-headline-xl {
    font-size: $font-size-headline;
  }
  .f-5-xl,
  .f-subheadline-xl {
    font-size: $font-size-subheadline;
  }
  .f1-xl { font-size: $font-size-1; }
  .f2-xl { font-size: $font-size-2; }
  .f3-xl { font-size: $font-size-3; }
  .f4-xl { font-size: $font-size-4; }
  .f5-xl { font-size: $font-size-5; }
  .f6-xl { font-size: $font-size-6; }
  .f7-xl { font-size: $font-size-7; }
}
