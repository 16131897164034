
// Converted Variables


// Custom Media Query Variables


/*

   SKINS
   Docs: http://tachyons.io/docs/themes/skins/

   Classes for setting foreground and background colors on elements.
   If you haven't declared a border color, but set border on an element, it will
   be set to the current text color.

*/

/* Text colors */

.black {         color: $black; }
.grey {          color: $grey; }
.light-silver {  color: $light-silver; }
.silver  {       color: $silver; }
.moon-grey {     color: $moon-grey; }
.light-grey {    color: $light-grey; }
.near-white {    color: $near-white; }
.white {         color: $white; }

.black-90 {       color: $black-90; }
.black-80 {       color: $black-80; }
.black-70 {       color: $black-70; }
.black-60 {       color: $black-60; }
.black-50 {       color: $black-50; }
.black-40 {       color: $black-40; }
.black-30 {       color: $black-30; }
.black-20 {       color: $black-20; }
.black-10 {       color: $black-10; }
.black-05 {       color: $black-05; }

.white-90 {       color: $white-90; }
.white-80 {       color: $white-80; }
.white-70 {       color: $white-70; }
.white-60 {       color: $white-60; }
.white-50 {       color: $white-50; }
.white-40 {       color: $white-40; }
.white-30 {       color: $white-30; }
.white-20 {       color: $white-20; }
.white-10 {       color: $white-10; }

.grey-90 {       color: $grey-90; }
.grey-80 {       color: $grey-80; }
.grey-70 {       color: $grey-70; }
.grey-60 {       color: $grey-60; }
.grey-50 {       color: $grey-50; }
.grey-40 {       color: $grey-40; }
.grey-30 {       color: $grey-30; }
.grey-20 {       color: $grey-20; }
.grey-10 {       color: $grey-10; }
.grey-05 {       color: $grey-05; }

.blue-dk { color: $blue-dark; }
.blue { color: $blue; }
.blue-lt { color: $blue-light; }
.dark-blue-dk { color: $dark-blue-dark; }
.dark-blue { color: $dark-blue; }
.dark-blue-lt { color: $dark-blue-light; }

.pink-dk { color: $pink-dark; }
.pink { color: $pink; }
.pink-lt { color: $pink-light; }
.purple-dk { color: $purple-dark; }
.purple { color: $purple; }
.purple-lt { color: $purple-light; }
.light-blue-dk { color: $light-blue-dark; }
.light-blue { color: $light-blue; }
.light-blue-lt { color: $light-blue-light; }

.red-dk { color: $red-dark; }
.red { color: $red; }
.red-lt { color: $red-light; }
.orange-dk { color: $orange-dark; }
.orange { color: $orange; }
.orange-lt { color: $orange-light; }
.green-dk { color: $green-dark; }
.green { color: $green; }
.green-lt { color: $green-light; }

.color-inherit { color: inherit; }


/* Background colors */

.bg-black {         background-color: $black; }
.bg-grey {          background-color: $grey; }
.bg-light-silver {  background-color: $light-silver; }
.bg-silver  {       background-color: $silver; }
.bg-moon-grey {     background-color: $moon-grey; }
.bg-light-grey {    background-color: $light-grey; }
.bg-near-white {    background-color: $near-white; }
.bg-white {         background-color: $white; }
.bg-transparent {   background-color: $transparent; }

.bg-black-90 {      background-color: $black-90; }
.bg-black-80 {      background-color: $black-80; }
.bg-black-70 {      background-color: $black-70; }
.bg-black-60 {      background-color: $black-60; }
.bg-black-50 {      background-color: $black-50; }
.bg-black-40 {      background-color: $black-40; }
.bg-black-30 {      background-color: $black-30; }
.bg-black-20 {      background-color: $black-20; }
.bg-black-10 {      background-color: $black-10; }
.bg-black-05 {      background-color: $black-05; }

.bg-white-90 {      background-color: $white-90; }
.bg-white-80 {      background-color: $white-80; }
.bg-white-70 {      background-color: $white-70; }
.bg-white-60 {      background-color: $white-60; }
.bg-white-50 {      background-color: $white-50; }
.bg-white-40 {      background-color: $white-40; }
.bg-white-30 {      background-color: $white-30; }
.bg-white-20 {      background-color: $white-20; }
.bg-white-10 {      background-color: $white-10; }

.bg-grey-90 {      background-color: $grey-90; }
.bg-grey-80 {      background-color: $grey-80; }
.bg-grey-70 {      background-color: $grey-70; }
.bg-grey-60 {      background-color: $grey-60; }
.bg-grey-50 {      background-color: $grey-50; }
.bg-grey-40 {      background-color: $grey-40; }
.bg-grey-30 {      background-color: $grey-30; }
.bg-grey-20 {      background-color: $grey-20; }
.bg-grey-10 {      background-color: $grey-10; }
.bg-grey-05 {      background-color: $grey-05; }

.bg-blue-dk { background-color: $blue-dark; }
.bg-blue { background-color: $blue; }
.bg-blue-lt { background-color: $blue-light; }
.bg-blue-ltr { background-color: $blue-lightest; }
.bg-dark-blue-lt { background-color: $dark-blue-light; }
.bg-dark-blue { background-color: $dark-blue; }
.bg-dark-blue-dk { background-color: $dark-blue-dark; }

.bg-pink-dk { background-color: $pink-dark; }
.bg-pink { background-color: $pink; }
.bg-pink-lt { background-color: $pink-light; }
.bg-purple-dk { background-color: $purple-dark; }
.bg-purple { background-color: $purple; }
.bg-purple-lt { background-color: $purple-light; }
.bg-light-blue-lt { background-color: $light-blue-light; }
.bg-light-blue { background-color: $light-blue; }
.bg-light-blue-dk { background-color: $light-blue-dark; }

.bg-red-dk { background-color: $red-dark; }
.bg-red { background-color: $red; }
.bg-red-lt { background-color: $red-light; }
.bg-orange-dk { background-color: $orange-dark; }
.bg-orange { background-color: $orange; }
.bg-orange-lt { background-color: $orange-light; }
.bg-green-dk { background-color: $green-dark; }
.bg-green { background-color: $green; }
.bg-green-lt { background-color: $green-light; }

.bg-inherit { background-color: inherit; }


/* Fill colors */

.fill-grey { fill: $cpi-grey; }
.fill-blue { fill: $cpi-blue; }