/* Replacement and additional modules for Tachyons */


/* EXTENDED MODULES ============================================= */

/*

These are module extensions that extend upon the default Tachyons
modules. Meaning both the default files and these need to be served.

*/

//@import 'src/scss/_tachyons-extended/box-sizing';
//@import 'src/scss/_tachyons-extended/aspect-ratios';
//@import 'src/scss/_tachyons-extended/images';
//@import 'src/scss/_tachyons-extended/background-size';
//@import 'src/scss/_tachyons-extended/background-position';
//@import 'src/scss/_tachyons-extended/outlines';
//@import 'src/scss/_tachyons-extended/borders';
//@import 'src/scss/_tachyons-extended/border-colors';
//@import 'src/scss/_tachyons-extended/border-radius';
//@import 'src/scss/_tachyons-extended/border-style';
@import 'src/scss/_tachyons-extended/border-widths';
//@import 'src/scss/_tachyons-extended/box-shadow';
//@import 'src/scss/_tachyons-extended/code';
//@import 'src/scss/_tachyons-extended/coordinates';
//@import 'src/scss/_tachyons-extended/clears';
//@import 'src/scss/_tachyons-extended/flexbox';
@import 'src/scss/_tachyons-extended/display';
@import 'src/scss/_tachyons-extended/floats';
//@import 'src/scss/_tachyons-extended/font-family';
//@import 'src/scss/_tachyons-extended/font-style';
//@import 'src/scss/_tachyons-extended/font-weight';
//@import 'src/scss/_tachyons-extended/forms';
//@import 'src/scss/_tachyons-extended/heights';
//@import 'src/scss/_tachyons-extended/letter-spacing';
//@import 'src/scss/_tachyons-extended/line-height';
@import 'src/scss/_tachyons-extended/links';
@import 'src/scss/_tachyons-extended/lists';
@import 'src/scss/_tachyons-extended/max-widths';
//@import 'src/scss/_tachyons-extended/widths';
//@import 'src/scss/_tachyons-extended/overflow';
@import 'src/scss/_tachyons-extended/position';
//@import 'src/scss/_tachyons-extended/opacity';
//@import 'src/scss/_tachyons-extended/rotations';
//@import 'src/scss/_tachyons-extended/skins';
//@import 'src/scss/_tachyons-extended/skins-pseudo';
@import 'src/scss/_tachyons-extended/spacing';
@import 'src/scss/_tachyons-extended/negative-margins';
//@import 'src/scss/_tachyons-extended/tables';
//@import 'src/scss/_tachyons-extended/text-decoration';
@import 'src/scss/_tachyons-extended/text-align';
//@import 'src/scss/_tachyons-extended/text-transform';
@import 'src/scss/_tachyons-extended/type-scale';
//@import 'src/scss/_tachyons-extended/typography';
//@import 'src/scss/_tachyons-extended/utilities';
//@import 'src/scss/_tachyons-extended/visibility';
//@import 'src/scss/_tachyons-extended/white-space';
@import 'src/scss/_tachyons-extended/word-break';
//@import 'src/scss/_tachyons-extended/vertical-align';
//@import 'src/scss/_tachyons-extended/hovers';
//@import 'src/scss/_tachyons-extended/z-index';
//@import 'src/scss/_tachyons-extended/nested';
//@import 'src/scss/_tachyons-extended/styles';


/* REPLACEMENT MODULES ============================================= */

/*

These are replacement modules that fully replace their counterpart
Tachyons modules. Meaning the default files must be commented out from
'_tachyons.scss' so they are not served twice.

*/

//@import 'src/scss/_tachyons-extended/box-sizing';
//@import 'src/scss/_tachyons-extended/aspect-ratios';
//@import 'src/scss/_tachyons-extended/images';
//@import 'src/scss/_tachyons-extended/background-size';
//@import 'src/scss/_tachyons-extended/background-position';
//@import 'src/scss/_tachyons-extended/outlines';
//@import 'src/scss/_tachyons-extended/borders';
@import 'src/scss/_tachyons-extended/border-colors';
//@import 'src/scss/_tachyons-extended/border-radius';
//@import 'src/scss/_tachyons-extended/border-style';
//@import 'src/scss/_tachyons-extended/border-widths';
//@import 'src/scss/_tachyons-extended/box-shadow';
//@import 'src/scss/_tachyons-extended/code';
//@import 'src/scss/_tachyons-extended/coordinates';
//@import 'src/scss/_tachyons-extended/clears';
@import 'src/scss/_tachyons-extended/flexbox';
//@import 'src/scss/_tachyons-extended/display';
//@import 'src/scss/_tachyons-extended/floats';
//@import 'src/scss/_tachyons-extended/font-family';
@import 'src/scss/_tachyons-extended/font-style';
@import 'src/scss/_tachyons-extended/font-weight';
//@import 'src/scss/_tachyons-extended/forms';
@import 'src/scss/_tachyons-extended/heights';
//@import 'src/scss/_tachyons-extended/letter-spacing';
//@import 'src/scss/_tachyons-extended/line-height';
//@import 'src/scss/_tachyons-extended/links';
//@import 'src/scss/_tachyons-extended/lists';
//@import 'src/scss/_tachyons-extended/max-widths';
@import 'src/scss/_tachyons-extended/widths';
//@import 'src/scss/_tachyons-extended/overflow';
//@import 'src/scss/_tachyons-extended/position';
//@import 'src/scss/_tachyons-extended/opacity';
//@import 'src/scss/_tachyons-extended/rotations';
@import 'src/scss/_tachyons-extended/skins';
@import 'src/scss/_tachyons-extended/skins-pseudo';
//@import 'src/scss/_tachyons-extended/spacing';
//@import 'src/scss/_tachyons-extended/negative-margins';
@import 'src/scss/_tachyons-extended/tables';
//@import 'src/scss/_tachyons-extended/text-decoration';
//@import 'src/scss/_tachyons-extended/text-align';
//@import 'src/scss/_tachyons-extended/text-transform';
//@import 'src/scss/_tachyons-extended/type-scale';
//@import 'src/scss/_tachyons-extended/typography';
//@import 'src/scss/_tachyons-extended/utilities';
//@import 'src/scss/_tachyons-extended/visibility';
//@import 'src/scss/_tachyons-extended/white-space';
//@import 'src/scss/_tachyons-extended/word-break';
//@import 'src/scss/_tachyons-extended/vertical-align';
@import 'src/scss/_tachyons-extended/hovers';
//@import 'src/scss/_tachyons-extended/z-index';
//@import 'src/scss/_tachyons-extended/nested';
@import 'src/scss/_tachyons-extended/styles';


/* NEW MODULES ============================================= */

/*

These are entirely new modules, not included with Tachyons.

*/

@import 'src/scss/_tachyons-extended/user-select';