/* VARIABLES AND MIXINS ============================================= */
@import "_variables.scss";
@import "_mixins.scss";


/* Footer Logos */

// KCMC logo
footer .logo {
    width: 5rem;
    height: 2.25rem;
}

// Funding logos
footer .f-logo {
    height: 1.5rem;
}
@media #{$breakpoint-not-small} {
    footer .f-logo {
        height: 2rem;
    }
}