
// Converted Variables


// Custom Media Query Variables


/* Variables */

/*
   SPACING
   Docs: http://tachyons.io/docs/layout/spacing/

   An eight step powers of two scale ranging from 0 to 16rem.

   Base:
     p = padding
     m = margin

   Modifiers:
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left

     0 = none
     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large
     -xl = xlarge

*/


@media #{$breakpoint-xlarge} {
  .pa0-xl  {  padding: $spacing-none; }
  .pa1-xl  {  padding: $spacing-extra-small; }
  .pa2-xl  {  padding: $spacing-small; }
  .pa3-xl  {  padding: $spacing-medium; }
  .pa4-xl  {  padding: $spacing-large; }
  .pa5-xl  {  padding: $spacing-extra-large; }
  .pa6-xl  {  padding: $spacing-extra-extra-large; }
  .pa7-xl  { padding: $spacing-extra-extra-extra-large; }

  .pl0-xl  {  padding-left: $spacing-none; }
  .pl1-xl  {  padding-left: $spacing-extra-small; }
  .pl2-xl  {  padding-left: $spacing-small; }
  .pl3-xl  {  padding-left: $spacing-medium; }
  .pl4-xl  {  padding-left: $spacing-large; }
  .pl5-xl  {  padding-left: $spacing-extra-large; }
  .pl6-xl  {  padding-left: $spacing-extra-extra-large; }
  .pl7-xl  { padding-left: $spacing-extra-extra-extra-large; }

  .pr0-xl  {  padding-right: $spacing-none; }
  .pr1-xl  {  padding-right: $spacing-extra-small; }
  .pr2-xl  {  padding-right: $spacing-small; }
  .pr3-xl  {  padding-right: $spacing-medium; }
  .pr4-xl  {  padding-right: $spacing-large; }
  .pr5-xl  {  padding-right: $spacing-extra-large; }
  .pr6-xl  {  padding-right: $spacing-extra-extra-large; }
  .pr7-xl  { padding-right: $spacing-extra-extra-extra-large; }

  .pb0-xl  {  padding-bottom: $spacing-none; }
  .pb1-xl  {  padding-bottom: $spacing-extra-small; }
  .pb2-xl  {  padding-bottom: $spacing-small; }
  .pb3-xl  {  padding-bottom: $spacing-medium; }
  .pb4-xl  {  padding-bottom: $spacing-large; }
  .pb5-xl  {  padding-bottom: $spacing-extra-large; }
  .pb6-xl  {  padding-bottom: $spacing-extra-extra-large; }
  .pb7-xl  { padding-bottom: $spacing-extra-extra-extra-large; }

  .pt0-xl  {  padding-top: $spacing-none; }
  .pt1-xl  {  padding-top: $spacing-extra-small; }
  .pt2-xl  {  padding-top: $spacing-small; }
  .pt3-xl  {  padding-top: $spacing-medium; }
  .pt4-xl  {  padding-top: $spacing-large; }
  .pt5-xl  {  padding-top: $spacing-extra-large; }
  .pt6-xl  {  padding-top: $spacing-extra-extra-large; }
  .pt7-xl  { padding-top: $spacing-extra-extra-extra-large; }

  .pv0-xl {
    padding-top: $spacing-none;
    padding-bottom: $spacing-none;
  }
  .pv1-xl {
    padding-top: $spacing-extra-small;
    padding-bottom: $spacing-extra-small;
  }
  .pv2-xl {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }
  .pv3-xl {
    padding-top: $spacing-medium;
    padding-bottom: $spacing-medium;
  }
  .pv4-xl {
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
  }
  .pv5-xl {
    padding-top: $spacing-extra-large;
    padding-bottom: $spacing-extra-large;
  }
  .pv6-xl {
    padding-top: $spacing-extra-extra-large;
    padding-bottom: $spacing-extra-extra-large;
  }
  .pv7-xl {
    padding-top: $spacing-extra-extra-extra-large;
    padding-bottom: $spacing-extra-extra-extra-large;
  }

  .ph0-xl {
    padding-left: $spacing-none;
    padding-right: $spacing-none;
  }
  .ph1-xl {
    padding-left: $spacing-extra-small;
    padding-right: $spacing-extra-small;
  }
  .ph2-xl {
    padding-left: $spacing-small;
    padding-right: $spacing-small;
  }
  .ph3-xl {
    padding-left: $spacing-medium;
    padding-right: $spacing-medium;
  }
  .ph4-xl {
    padding-left: $spacing-large;
    padding-right: $spacing-large;
  }
  .ph5-xl {
    padding-left: $spacing-extra-large;
    padding-right: $spacing-extra-large;
  }
  .ph6-xl {
    padding-left: $spacing-extra-extra-large;
    padding-right: $spacing-extra-extra-large;
  }
  .ph7-xl {
    padding-left: $spacing-extra-extra-extra-large;
    padding-right: $spacing-extra-extra-extra-large;
  }

  .ma0-xl  {  margin: $spacing-none; }
  .ma1-xl  {  margin: $spacing-extra-small; }
  .ma2-xl  {  margin: $spacing-small; }
  .ma3-xl  {  margin: $spacing-medium; }
  .ma4-xl  {  margin: $spacing-large; }
  .ma5-xl  {  margin: $spacing-extra-large; }
  .ma6-xl  {  margin: $spacing-extra-extra-large; }
  .ma7-xl  { margin: $spacing-extra-extra-extra-large; }

  .ml0-xl  {  margin-left: $spacing-none; }
  .ml1-xl  {  margin-left: $spacing-extra-small; }
  .ml2-xl  {  margin-left: $spacing-small; }
  .ml3-xl  {  margin-left: $spacing-medium; }
  .ml4-xl  {  margin-left: $spacing-large; }
  .ml5-xl  {  margin-left: $spacing-extra-large; }
  .ml6-xl  {  margin-left: $spacing-extra-extra-large; }
  .ml7-xl  { margin-left: $spacing-extra-extra-extra-large; }

  .mr0-xl  {  margin-right: $spacing-none; }
  .mr1-xl  {  margin-right: $spacing-extra-small; }
  .mr2-xl  {  margin-right: $spacing-small; }
  .mr3-xl  {  margin-right: $spacing-medium; }
  .mr4-xl  {  margin-right: $spacing-large; }
  .mr5-xl  {  margin-right: $spacing-extra-large; }
  .mr6-xl  {  margin-right: $spacing-extra-extra-large; }
  .mr7-xl  { margin-right: $spacing-extra-extra-extra-large; }

  .mb0-xl  {  margin-bottom: $spacing-none; }
  .mb1-xl  {  margin-bottom: $spacing-extra-small; }
  .mb2-xl  {  margin-bottom: $spacing-small; }
  .mb3-xl  {  margin-bottom: $spacing-medium; }
  .mb4-xl  {  margin-bottom: $spacing-large; }
  .mb5-xl  {  margin-bottom: $spacing-extra-large; }
  .mb6-xl  {  margin-bottom: $spacing-extra-extra-large; }
  .mb7-xl  { margin-bottom: $spacing-extra-extra-extra-large; }

  .mt0-xl  {  margin-top: $spacing-none; }
  .mt1-xl  {  margin-top: $spacing-extra-small; }
  .mt2-xl  {  margin-top: $spacing-small; }
  .mt3-xl  {  margin-top: $spacing-medium; }
  .mt4-xl  {  margin-top: $spacing-large; }
  .mt5-xl  {  margin-top: $spacing-extra-large; }
  .mt6-xl  {  margin-top: $spacing-extra-extra-large; }
  .mt7-xl  { margin-top: $spacing-extra-extra-extra-large; }

  .mv0-xl {
    margin-top: $spacing-none;
    margin-bottom: $spacing-none;
  }
  .mv1-xl {
    margin-top: $spacing-extra-small;
    margin-bottom: $spacing-extra-small;
  }
  .mv2-xl {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
  }
  .mv3-xl {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
  }
  .mv4-xl {
    margin-top: $spacing-large;
    margin-bottom: $spacing-large;
  }
  .mv5-xl {
    margin-top: $spacing-extra-large;
    margin-bottom: $spacing-extra-large;
  }
  .mv6-xl {
    margin-top: $spacing-extra-extra-large;
    margin-bottom: $spacing-extra-extra-large;
  }
  .mv7-xl {
    margin-top: $spacing-extra-extra-extra-large;
    margin-bottom: $spacing-extra-extra-extra-large;
  }

  .mh0-xl {
    margin-left: $spacing-none;
    margin-right: $spacing-none;
  }
  .mh1-xl {
    margin-left: $spacing-extra-small;
    margin-right: $spacing-extra-small;
  }
  .mh2-xl {
    margin-left: $spacing-small;
    margin-right: $spacing-small;
  }
  .mh3-xl {
    margin-left: $spacing-medium;
    margin-right: $spacing-medium;
  }
  .mh4-xl {
    margin-left: $spacing-large;
    margin-right: $spacing-large;
  }
  .mh5-xl {
    margin-left: $spacing-extra-large;
    margin-right: $spacing-extra-large;
  }
  .mh6-xl {
    margin-left: $spacing-extra-extra-large;
    margin-right: $spacing-extra-extra-large;
  }
  .mh7-xl {
    margin-left: $spacing-extra-extra-extra-large;
    margin-right: $spacing-extra-extra-extra-large;
  }
}