
// Converted Variables


// Custom Media Query Variables


/*

   DISPLAY
   Docs: http://tachyons.io/docs/layout/display

   Base:
    d = display

   Modifiers:
    n     = none
    b     = block
    ib    = inline-block
    it    = inline-table
    t     = table
    tc    = table-cell
    tr    = table-row
    tcol  = table-column
    tcolg = table-column-group

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large
     -xl = xlarge

*/

@media #{$breakpoint-xlarge} {
  .dn-xl {              display: none; }
  .di-xl {              display: inline; }
  .db-xl {              display: block; }
  .dib-xl {             display: inline-block; }
  .dit-xl {             display: inline-table; }
  .dt-xl {              display: table; }
  .dtc-xl {             display: table-cell; }
  .dt-row-xl {          display: table-row; }
  .dt-row-group-xl {    display: table-row-group; }
  .dt-column-xl {       display: table-column; }
  .dt-column-group-xl { display: table-column-group; }

  .dt--fixed-xl {
    table-layout: fixed;
    width: 100%;
  }
}


