
// Converted Variables


// Custom Media Query Variables


/*
   NEGATIVE MARGINS

   Base:
     n = negative

   Modifiers:
     a = all
     t = top
     r = right
     b = bottom
     l = left

     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large
     -xl = xlarge

*/


@media #{$breakpoint-xlarge} {
  .na1-xl { margin: -$spacing-extra-small; }
  .na2-xl { margin: -$spacing-small; }
  .na3-xl { margin: -$spacing-medium; }
  .na4-xl { margin: -$spacing-large; }
  .na5-xl { margin: -$spacing-extra-large; }
  .na6-xl { margin: -$spacing-extra-extra-large; }
  .na7-xl { margin: -$spacing-extra-extra-extra-large; }

  .nl1-xl { margin-left: -$spacing-extra-small; }
  .nl2-xl { margin-left: -$spacing-small; }
  .nl3-xl { margin-left: -$spacing-medium; }
  .nl4-xl { margin-left: -$spacing-large; }
  .nl5-xl { margin-left: -$spacing-extra-large; }
  .nl6-xl { margin-left: -$spacing-extra-extra-large; }
  .nl7-xl { margin-left: -$spacing-extra-extra-extra-large; }

  .nr1-xl { margin-right: -$spacing-extra-small; }
  .nr2-xl { margin-right: -$spacing-small; }
  .nr3-xl { margin-right: -$spacing-medium; }
  .nr4-xl { margin-right: -$spacing-large; }
  .nr5-xl { margin-right: -$spacing-extra-large; }
  .nr6-xl { margin-right: -$spacing-extra-extra-large; }
  .nr7-xl { margin-right: -$spacing-extra-extra-extra-large; }

  .nb1-xl { margin-bottom: -$spacing-extra-small; }
  .nb2-xl { margin-bottom: -$spacing-small; }
  .nb3-xl { margin-bottom: -$spacing-medium; }
  .nb4-xl { margin-bottom: -$spacing-large; }
  .nb5-xl { margin-bottom: -$spacing-extra-large; }
  .nb6-xl { margin-bottom: -$spacing-extra-extra-large; }
  .nb7-xl { margin-bottom: -$spacing-extra-extra-extra-large; }

  .nt1-xl { margin-top: -$spacing-extra-small; }
  .nt2-xl { margin-top: -$spacing-small; }
  .nt3-xl { margin-top: -$spacing-medium; }
  .nt4-xl { margin-top: -$spacing-large; }
  .nt5-xl { margin-top: -$spacing-extra-large; }
  .nt6-xl { margin-top: -$spacing-extra-extra-large; }
  .nt7-xl { margin-top: -$spacing-extra-extra-extra-large; }
}
