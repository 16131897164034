
// Converted Variables


// Custom Media Query Variables


/*

   LISTS
   http://tachyons.io/docs/elements/lists/

*/
.list {
  li { margin-bottom: 0.25rem; }
}
.ordered-list {
    list-style: none;
    padding-left: $spacing-medium;
    counter-reset: circle-counter;
    margin-right: $spacing-medium*2;
    margin-left: 1.6em;
    text-indent: -2.2rem;

    li {
        counter-increment: circle-counter;
        &:before {
            content: counter(circle-counter);
            background-color: $kcmc-grey;
            width: $font-size-5*1.4;
            height: $font-size-5*1.4;
            border-radius: 50%;
            display: inline-block;
            font-family: $sans-serif;
font-weight: $font-bold; font-weight: 700;
            font-size: $font-size-5*.65;
            line-height: $font-size-5*1.3;
            color: $white;
            text-align: center;
            margin-right: 0.3rem;
            margin-left: 0.2rem;
            position: relative;
            text-indent: 0rem;
            top:-1px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}