
// Converted Variables


// Custom Media Query Variables


/*

  WORD BREAK

   Base:
     word = word-break

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large
     -xl = xlarge

*/

@media #{$breakpoint-xlarge} {
  .word-normal-xl { word-break: normal; }
  .word-wrap-xl { word-break: break-all; }
  .word-nowrap-xl { word-break: keep-all; }
}

